import { useState, useCallback } from 'react';

import { useLocalStorage } from './hooks/useLocalStorage';

function App() {
  const [prompt, setPrompt] = useState<string | null>(null);
  const [inflightPrompt, setInflightPrompt] = useState<string | null>(null);
  const [randomQuestion, setRandomQuestion] = useState<string>(
    RANDOM_QUESTIONS[Math.floor(Math.random() * RANDOM_QUESTIONS.length)]
  );

  const [promptAndResponseHistory, setPromptAndResponseHistory] =
    useLocalStorage<
      Array<{
        Prompt: string;
        Response: string;
      }>
    >('ai_prompt_history', []);

  const submitPrompt = useCallback(
    async (promptToSubmit: string) => {
      setInflightPrompt(promptToSubmit);
      setRandomQuestion(
        RANDOM_QUESTIONS[Math.floor(Math.random() * RANDOM_QUESTIONS.length)]
      );
      const response = await fetch(
        'https://api.openai.com/v1/engines/text-curie-001/completions',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
          body: JSON.stringify({
            prompt: promptToSubmit,
            temperature: 0.5,
            max_tokens: 64,
            top_p: 1.0,
            frequency_penalty: 0.0,
            presence_penalty: 0.0,
          }),
        }
      );

      const result = await response.json();

      setPromptAndResponseHistory((history) => [
        { Prompt: promptToSubmit, Response: result.choices[0].text },
        ...history,
      ]);
      setPrompt(null);
      setInflightPrompt(null);
    },
    [setPromptAndResponseHistory]
  );

  return (
    <div className='main-wrapper'>
      <h1 className='title'>Fun with AI</h1>
      <form
        className='form-wrapper'
        onSubmit={async (e) => {
          e.preventDefault();
          submitPrompt(prompt ?? randomQuestion);
        }}
      >
        <label>
          Enter prompt
          <textarea
            rows={10}
            cols={75}
            autoFocus={true}
            value={prompt ?? ''}
            onChange={(e) => {
              e.preventDefault();
              setPrompt(e.target.value);
            }}
            placeholder={`example: ${randomQuestion}`}
          />
        </label>

        <button className='submit-button' type='submit'>
          Submit
        </button>
      </form>

      <h2 className='responses-heading'>Responses</h2>

      {promptAndResponseHistory.length || inflightPrompt ? (
        <ol className='responses-wrapper'>
          {(inflightPrompt
            ? [
                {
                  Prompt: inflightPrompt,
                  Response: <span className='thinking'>🤔</span>,
                },
                ...promptAndResponseHistory,
              ]
            : promptAndResponseHistory
          ).map((response, index) => (
            <li key={index}>
              <div className='history-prompt-wrapper'>
                <p className='history-prompt'>{response.Prompt}</p>
                <div className='avatar' role='presentation'>
                  🧐
                </div>
              </div>
              <div className='history-response-wrapper'>
                <div className='avatar' role='presentation'>
                  🤖
                </div>
                <p className='history-response'>{response.Response}</p>
              </div>
              <div className='divider' />
            </li>
          ))}
        </ol>
      ) : (
        <p className='empty-state'>🦗 nothing yet </p>
      )}
    </div>
  );
}

const RANDOM_QUESTIONS = [
  `Who is the CEO of Shopify?`,
  `Where is Shopify's Headquarters?`,
  `What is Shopify known for?`,
  `How long is Toronto to Ottawa by roller blade?`,
  `How long does it take to walk from New York City to LA?`,
  `What is the safe internal temperature to cook cucumber?`,
  `Please tell me a joke`,
  `What's the most expensive boardgame?`,
  `Is a hot dog a sandwich?`,
  `Is a sandwich a sandwich?`,
];

export default App;
